
import { defineComponent, reactive, ref, onMounted } from "vue";
import HomeHead from "@/components/HomeHead.vue";
import HtmlFooter from "@/components/HtmlFooter.vue";
import CurrentAddress from "@/components/common/CurrentAddress.vue";
import GoodsList from "@/components/goods/project/GoodsList.vue";
import PartsList from "@/components/goods/project/PartsList.vue";
import PageShow from "@/components/common/PageShow.vue";
import VideoLine from "@/components/video/VideoSearchLine.vue";
import { goodsApi } from "@/service/api";
import { RouteRecordName, useRoute, useRouter } from "vue-router";
import FreeSearchLine from "@/components/goods/FreeSearchLine.vue";
import { stringify } from "qs";

export default defineComponent({
  components: {
    HomeHead,
    HtmlFooter,
    CurrentAddress,
    GoodsList,
    PageShow,
    VideoLine,
    FreeSearchLine,
    PartsList,
  },
  setup() {
    let total = ref(100);
    let loading = ref(true);
    let handleCurrentChange = (val: number) => {
      pageIndex.value = val;
      pageShow.value.changePageIndex(pageIndex.value);
      updateUrl(pageIndex.value);
      getGoodsList();
    };
    let currentTab = ref("GoodsList");
    let tabs = reactive([
      "GoodsList",
      "VideoLine",
      "FreeSearchLine",
      "PartsList",
    ]);
    let sortType = ref(1);
    let pageIndex = ref(1);

    const pageShow = ref<any>();
    const route = useRoute();
    if (route.params.page) {
      pageIndex.value = parseInt(route.params.page as string);
    }
    if (route.params.type) {
      let curre = parseInt(route.params.type as string);
      currentTab.value = tabs[curre - 1];
    }
    //console.log(pageIndex.value, "sdfasdfsdfsdfasdfasdfs")
    //获取参数
    interface goodsType {
      data: any;
      errno: number;
      errmsg: string;
      total: number;
      pageSize: number;
    }
    let goodslit: goodsType = reactive({
      data: [],
      errno: 0,
      errmsg: "",
      total: 0,
      pageSize: 20,
    });

    let type = ref(parseInt(route.params.type as string));
    let sv = ref(route.params.sv);

    let isShowPage = ref(false);

    let change = (val: number) => {
      if (!loading.value) {
        loading.value = true;
        currentTab.value = tabs[val];
        type.value = val + 1;
        sortType.value = 1;
        pageIndex.value = 1;
        isShowPage.value = false;

        handleCurrentChange(pageIndex.value);
      }
    };

    let changeSoftType = (val: number) => {
      if (val == 5) {
        if (sortType.value != 5) {
          sortType.value = 5;
        } else {
          sortType.value = 6;
        }
      } else {
        sortType.value = val;
      }

      //console.log(sortType.value)
      getGoodsList();
      // type.value = val
    };

    let clickSearch = (svs: string, types: number) => {
      sv.value = svs;
      type.value = types;
      console.log('types', types)
      goodsApi
        .searchGoods(sv.value as string, type.value, sortType.value, 0, pageIndex.value)
        .then((res: any) => {
          if (res.errno == 0) {
            goodslit.data = res.datas.data;
            goodslit.total = res.datas.total;
            goodslit.pageSize = res.datas.pageSize;
            isShowPage.value = true;
            if (res.datas.data == 0) {
              types++
              clickSearch(svs, types)
            } else {
              currentTab.value = tabs[type.value - 1];
            }
          }
        })
    };

    //获取商品列表
    let getGoodsList = () => {
      let sort;
      let price;
      loading.value = true;
      if (sortType.value < 5) {
        // sort['']
        sort = sortType.value;
        price = 0;
      } else {
        sort = 0;
        if (sortType.value == 5) {
          price = 1;
        } else {
          price = 2;
        }
      }
      console.log('search')
      goodsApi
        .searchGoods(
          sv.value as string,
          type.value,
          sort,
          price,
          pageIndex.value
        )
        .then((res: any) => {
          if (res.errno == 0) {
            goodslit.data = res.datas.data;
            goodslit.total = res.datas.total;
            goodslit.pageSize = res.datas.pageSize;
            isShowPage.value = true;

          }
          loading.value = false;
        });
    };

    let changePageIndex = (type: number) => {
      if (type == 1) {
        //加
        Math.ceil(goodslit.total / goodslit.pageSize);
        if (pageIndex.value < Math.ceil(goodslit.total / goodslit.pageSize)) {
          pageIndex.value = pageIndex.value + 1;
          pageShow.value.changePageIndex(pageIndex.value);
          getGoodsList();
        }
      } else {
        //减
        if (pageIndex.value > 1) {
          pageIndex.value = pageIndex.value - 1;
          pageShow.value.changePageIndex(pageIndex.value);
          getGoodsList();
        }
        //pageShow.value.changePageIndex(1)
      }
      updateUrl(pageIndex.value);
    };
    let router = useRouter();

    let updateUrl = (val: number) => {
      let params = {
        type: type.value,
        sv: sv.value,
        page: val + "",
      };

      router.push({
        name: route.name as RouteRecordName,
        params,
      });
    };
    let gotoHome = () => {
      if (type.value == 4) {
        router.push({
          name: "spareHome",
        })
      } else {
        router.push({
          name: "home",
        })
      }
    }
    function searchGoods() {
      goodsApi
        .searchGoods(sv.value as string, type.value, sortType.value, 0, pageIndex.value)
        .then((res: any) => {
          if (res.errno == 0) {
            goodslit.data = res.datas.data;
            goodslit.total = res.datas.total;
            goodslit.pageSize = res.datas.pageSize;
            isShowPage.value = true;
            if (res.datas.data == 0) {
              type.value += 1;
              searchGoods();
            } else {
              currentTab.value = tabs[type.value - 1];
            }
          }
        });
    }

    searchGoods(); // 页面加载时执行一次

    onMounted(() => {
      getGoodsList()
    });

    return {
      total,
      handleCurrentChange,
      currentTab,
      tabs,
      change,
      changeSoftType,
      type,
      goodslit,
      loading,
      sortType,
      pageIndex,
      isShowPage,
      pageShow,
      changePageIndex,
      clickSearch,
      gotoHome,
    };
  },
});
